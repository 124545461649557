import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import BackgroundPattern from "../../assets/backgroundPattern.svg";
import React from "react";

const RsvpSuccessModel = ({ isOpen }) => {
  const { onOpen, onClose } = useDisclosure();
  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <Box
            backgroundImage={BackgroundPattern}
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            backgroundPosition="center"
          >
            <ModalHeader marginTop="2rem">
              <Heading variant="h4" color="secondary.900" textAlign="center">
                Check-in Successful
              </Heading>
            </ModalHeader>
            <ModalBody marginBottom="2rem">
              <Text variant="p" color="secondary.900" textAlign="center">
                Guest successfully checked in, window will automatically close
                shortly.
              </Text>
            </ModalBody>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RsvpSuccessModel;
