import { Flex, Heading } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const NavBar = () => {
  return (
    <Flex
      classname="nav-bar"
      width="100%"
      borderBottom="1px solid #e2d3bd"
      padding={{ base: "2rem 0 0.75rem 0", lg: "0rem 0 0.75rem 0" }}
      justifyContent="center"
      gap="10rem"
    >
      <Link to="/dashboard" className="menu-item">
        <Heading variant="h4" color="secondary.900">
          Dashboard
        </Heading>
      </Link>
      <Link to="/check-in" className="menu-item">
        <Heading variant="h4" color="secondary.900">
          Check In
        </Heading>
      </Link>
    </Flex>
  );
};

export default NavBar;
