import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import SmallPattern from "../../assets/small-pattern.svg";
import React, { useEffect, useState } from "react";
import RsvpForm from "../components/RsvpForm";
import BackgroundPattern from "../../assets/backgroundPattern.svg";
import Logo from "../../assets/Duma Logo.svg";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import NavBar from "../components/NavBar";

const Rsvp = () => {
  const gridItemStyling = {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  };

  // Logo Definitions and Location
  const logos = ["Duma Logo.svg", "Nirox Logo.svg", "Farmhouse Logo.png"];

  // Handle Location Logic
  const location = useLocation();

  const [activeUrl, setActiveUrl] = useState(undefined);

  useEffect(() => {
    if (location.pathname == "/last-rsvp" || location.pathname == "/artist") {
      setActiveUrl("Rsvp");
    } else {
      setActiveUrl(undefined);
    }
  }, [location]);

  return (
    <>
      <Helmet>
        <title>Duma Collective Kick Start</title>
        <meta
          name="description"
          content="Duma Collective cordially invites you to the Duma Collective Year Kick Start 2024."
        />
        <link rel="canonical" href="/" />
        <meta name="robots" content="noindex" />
      </Helmet>

      <Box
        className="page-container"
        minHeight="100vh"
        minWidth="100vw"
        backgroundImage={BackgroundPattern}
        bgRepeat="none"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding="2rem"
      >
        <Grid
          gridTemplateColumns={{ base: "1fr", lg: "1fr 1fr" }}
          minWidth="100vw"
          height="100%"
          className="body-container"
          marginY={{ base: "4rem", md: "2rem", lg: "0" }}
        >
          <GridItem className="heading-container" sx={gridItemStyling}>
            <Heading
              fontFamily="Avenir"
              variant="h1"
              textTransform="uppercase"
              fontWeight="300"
            >
              Duma Collective <br />
              <Box as="span" fontWeight="600" fontFamily="Avenir Black">
                Kickstart 2024
              </Box>
            </Heading>
            <Image
              src={SmallPattern}
              height={{ base: "350px", sm: "400px", md: "450px", lg: "500px" }}
              width={{ base: "350px", sm: "400px", md: "450px", lg: "500px" }}
            />
          </GridItem>
          <GridItem
            className="form-container"
            sx={gridItemStyling}
            marginTop={{ base: "1rem", lg: "0" }}
          >
            {activeUrl && activeUrl === "Rsvp" ? (
              <>
                <Heading variant="h1" textTransform="uppercase">
                  Mark your calendars
                </Heading>
                <Heading marginY="0.25rem" variant="h5" fontSize="1.25rem">
                  20 January 2024, 14:00 - 22:00
                </Heading>
                <Text variant="p">
                  Duma Collective cordially invites you to the Duma Collective
                  Year Kick Start 2024. Kindly confirm whether you will be
                  joining us by using the prompts below.
                </Text>
                <RsvpForm />
              </>
            ) : (
              <Box className="rsvp-full-body" marginX="2rem">
                <Heading variant="h1" textTransform="uppercase">
                  RSVP Closed
                </Heading>

                <Text variant="p">
                  You missed the RSVP date and the list is now fully subscribed.
                  Please join us next year. Do not come to the venue as you will
                  be respectfully denied entry.
                </Text>
              </Box>
            )}
            <Flex
              flexDirection={{ base: "column", md: "row" }}
              justifyContent={{ base: "center", md: "space-between" }}
              alignItems={{ base: "center", md: "space-between" }}
              marginY="1rem"
              width="90%"
            >
              {logos?.map((logo, index) => (
                <Image
                  key={index}
                  src={require(`../../assets/${logo}`)}
                  height="60px"
                  width="169px"
                />
              ))}
            </Flex>
          </GridItem>
        </Grid>
      </Box>
    </>
  );
};

export default Rsvp;
