import React from "react";
import BackgroundPattern from "../../assets/backgroundPattern.svg";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import ReactPlayer from "react-player";
import { Helmet } from "react-helmet-async";

const ThankYou = () => {
  // Images
  const historicImages = [
    "Kickstart 7.jpg",
    "Kickstart 8.jpg",
    "Kickstart 9.jpg",
    "Kickstart 10.jpg",
    "Kickstart 6.jpg",
    "Kickstart 2.jpg",
    "Kickstart 11.jpg",
    "Kickstart 4.jpg",
    "Kickstart 5.jpg",
  ];

  return (
    <>
      <Helmet>
        <title>Thank You</title>
        <meta
          name="description"
          content="Attendance status successfully submitted. More information will be shared in due time."
        />
        <link rel="canonical" href="/thank-you" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Box
        className="page-container"
        minHeight="100vh"
        minWidth="100vw"
        backgroundImage={BackgroundPattern}
        bgRepeat="none"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding={{
          base: "14rem 2rem 19rem 2rem",
          md: "10rem 2rem 9rem 2rem",
          lg: "2rem",
        }}
      >
        <Box marginBottom={{ base: "0", lg: "2rem" }}>
          <Heading variant="h3" textAlign="center">
            {" "}
            Thank you
          </Heading>
          <Text textAlign="center" variant="p">
            Attendance status successfully submitted. Please note that no plus
            ones are allowed, more information will be shared in due time. In
            the meantime, why don't you take a trip down memory lane and revisit
            how we ushered in 2023.
          </Text>
        </Box>
        <Grid
          gridTemplateColumns={{ base: "1fr", lg: "1fr 1fr" }}
          minWidth="100vw"
          height="100%"
          className="body-container"
          marginY={{ base: "4rem", md: "2rem", lg: "0" }}
          gap={{ base: "2rem", lg: "0" }}
        >
          <GridItem marginX="1rem" display="flex" alignItems="center">
            <Flex
              style={{ aspectRatio: "16/9" }}
              justifyContent="center"
              width="100%"
            >
              <ReactPlayer
                className="video-player"
                controls
                url="https://www.youtube.com/watch?v=G7tFeBEY5Vg"
                width="100%"
                height="100%"
                loop={true}
                playing={true}
                muted={true}
                config={{
                  youtube: {
                    playerVars: { showinfo: 0, modestbranding: 1 },
                  },
                }}
                progressInterval={1000}
              />
            </Flex>
          </GridItem>
          <GridItem display="flex" alignItems="center">
            <Grid
              gridTemplateColumns={{
                base: "1fr",
                sm: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)",
              }}
              gap="0.5rem"
              marginX="1rem"
            >
              {historicImages?.map((image, index) => (
                <GridItem display="flex" justifyContent="center">
                  <Image
                    key={index}
                    src={require(`../../assets/images/${image}`)}
                    width="213"
                    // height={{ base: "auto", lg: " 142" }}
                  />
                </GridItem>
              ))}
            </Grid>
          </GridItem>
        </Grid>
      </Box>
    </>
  );
};

export default ThankYou;
