import React, { useEffect, useState } from "react";
import BackgroundPattern from "../../assets/backgroundPattern.svg";
import { Box, Button, Flex, Heading, Input, Text } from "@chakra-ui/react";
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../actions/useFetchFirebase";
import { useNavigate } from "react-router-dom";

const Login = () => {
  // Styling
  const inputContainer = {
    borderLeft: "none",
    borderRight: "none",
    borderTop: "none",
    borderBottom: "1px solid #e2d3bd",
    borderRadius: "0",
    backgroundColor: "transparent",
    width: { base: "20rem", sm: "25rem" },

    color: "secondary.900",
    _placeholder: {
      color: "secondary.900",
    },
  };

  const [inputData, setInputData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  // Handle Auth
  const [isAuthDataPending, setIsAuthDataPending] = useState(false);
  const [isSignInSuccess, setIsSignInSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    setIsAuthDataPending(true);
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        inputData.email,
        inputData.password
      );
      setIsAuthDataPending(false);
      setIsSignInSuccess(true);
    } catch (error) {
      let errorMessage = "";

      if (error.code === "auth/user-not-found") {
        errorMessage = "Account does not exist.";
      } else if (error.code === "auth/wrong-password") {
        errorMessage = "Invalid Password.";
      } else if (error.code === "auth/invalid-email") {
        errorMessage = "Invalid email address.";
      } else if (inputData.email === "") {
        setErrorMessage("Email address is required.");
      } else if (inputData.password === "") {
        setErrorMessage("Password is required.");
      } else {
        errorMessage = "An error occurred. Please try again.";
      }
      setIsAuthDataPending(false);

      setErrorMessage(errorMessage);
    }
  };

  const [user, setUser] = useState(undefined);

  useEffect(() => {
    const activeUser = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => activeUser();
  }, []);

  // Handle Navigate after successful sign up
  useEffect(() => {
    if (isSignInSuccess) {
      navigate("/dashboard");
    }
  }, [isSignInSuccess, navigate]);

  return (
    <Box
      className="page-container"
      minHeight="100vh"
      minWidth="100vw"
      backgroundImage={BackgroundPattern}
      bgRepeat="none"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      padding={{
        base: "8rem 2rem 8rem 2rem",
        md: "10rem 2rem 9rem 2rem",
        lg: "2rem",
      }}
    >
      <Heading variant="h3" textAlign="center">
        Login
      </Heading>

      <Flex flexDir="column" width="100%" marginTop="2rem" gap="1.5rem">
        <Box>
          <Input
            variant="unstyled"
            sx={inputContainer}
            placeholder="Email"
            name="email"
            value={inputData.email}
            onChange={handleInputChange}
          />
          {errorMessage == "Invalid email address." ||
          errorMessage == "Account does not exist." ? (
            <Text color="secondary.900">{errorMessage}</Text>
          ) : null}
        </Box>
        <Box>
          <Input
            variant="unstyled"
            sx={inputContainer}
            placeholder="Password"
            name="password"
            value={inputData.password}
            onChange={handleInputChange}
          />

          {errorMessage == "Invalid Password." ||
          errorMessage == "An error occurred. Please try again." ||
          errorMessage == "Password is required." ? (
            <Text color="secondary.900">{errorMessage}</Text>
          ) : null}
        </Box>
        <Box>
          <Button
            isLoading={isAuthDataPending ? true : false}
            loadingText="Logging In"
            spinnerPlacement="end"
            variant="primaryButton"
            width={{ base: "20rem", sm: "25rem" }}
            onClick={handleLogin}
          >
            Login
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default Login;
