import { Box, Button, Flex, Input, Select, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import firebase from "firebase/compat/app";
import { useFetchFirebase } from "../../actions/useFetchFirebase";
import { useLocation, useNavigate } from "react-router-dom";

const RsvpForm = () => {
  // Styling
  const inputContainer = {
    borderLeft: "none",
    borderRight: "none",
    borderTop: "none",
    borderBottom: "1px solid #e2d3bd",
    borderRadius: "0",
    backgroundColor: "transparent",

    color: "secondary.900",
    _placeholder: {
      color: "secondary.900",
    },
  };

  const inputRow = {
    gap: "1rem",
    marginY: "1rem",
    flexDirection: {
      base: "column",
      md: "row",
    },
  };

  // Page Location
  const location = useLocation();

  //   Handle InputData
  const [inputData, setInputData] = useState({
    name: "",
    surname: "",
    workEmail: "",
    privateEmail: "",
    contactNumber: "",
    rsvpStatus: "",
    category: location.pathname == "/artist" ? "artist" : "last-guest",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  //   RSVP Options
  const rsvpOptions = ["Yes", "No", "Maybe"];

  // Error Handling & Form Submission
  const [errorMessage, setErrorMessage] = useState("");
  const [isRsvpSuccess, setIsRsvpSuccess] = useState(false);
  const [isRsvpPending, setIsRsvpPending] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  // Handle Submit
  const handleSubmit = async () => {
    setIsRsvpPending(true);
    try {
      // Validate form inputs
      if (inputData.name === "") {
        setErrorMessage("Kindly fill in your name");
        return;
      } else if (inputData.surname === "" && location.pathname === "/") {
        setErrorMessage("Kindly fill in your surname");
        return;
      } else if (inputData.workEmail === "") {
        setErrorMessage("Kindly fill in your work email");
        return;
      } else if (inputData.privateEmail === "") {
        setErrorMessage("Kindly fill in your personal email");
        return;
      } else if (inputData.contactNumber === "") {
        setErrorMessage("Kindly fill in your contact number");
        return;
      } else if (inputData.rsvpStatus === "") {
        setErrorMessage("Kindly confirm your RSVP status");
        return;
      }

      // Post to Firebase
      const database = firebase.database();
      const databaseRef = database.ref(
        `events/kickStart24/rsvpd/${inputData.name} ${inputData.surname}`
      );

      databaseRef.set(inputData, (error) => {
        if (error) {
          setErrorMessage("Could not complete registration, please try again.");
        } else {
          setIsRsvpSuccess(true);

          setErrorMessage("");
          setInputData({
            name: "",
            surname: "",
            workEmail: "",
            privateEmail: "",
            contactNumber: "",
            rsvpStatus: "",
          });
          navigate("/thank-you");
        }
      });
    } catch (error) {
      console.error("Error during registration:", error);
      setErrorMessage("An error occurred during registration");
    } finally {
      setIsRsvpPending(false);
    }
  };

  return (
    <Box className="form-container" margin="1rem 2rem 2rem 2rem" width="90%">
      <Flex sx={inputRow} className="input-row" gap="1rem">
        <Box width="100%">
          <Input
            variant="unstyled"
            sx={inputContainer}
            placeholder={
              location.pathname == "/last-rsvp" ? "First Name" : "Artist Name"
            }
            name="name"
            value={inputData.name}
            onChange={handleInputChange}
          />
          {errorMessage === "Kindly fill in your name" ? (
            <Text variant="p" mt={2}>
              {errorMessage}
            </Text>
          ) : null}
        </Box>
        {location.pathname == "/last-rsvp" ? (
          <Box width="100%">
            <Input
              variant="unstyled"
              sx={inputContainer}
              placeholder="Surname"
              name="surname"
              value={inputData.surname}
              onChange={handleInputChange}
            />
            {errorMessage === "Kindly fill in your surname" ? (
              <Text variant="p" mt={2}>
                {errorMessage}
              </Text>
            ) : null}
          </Box>
        ) : null}
      </Flex>
      <Flex sx={inputRow} className="input-row" gap="1rem">
        <Box width="100%">
          <Input
            variant="unstyled"
            sx={inputContainer}
            placeholder="Work Email"
            name="workEmail"
            value={inputData.workEmail}
            onChange={handleInputChange}
          />
          {errorMessage === "Kindly fill in your work email" ? (
            <Text variant="p" mt={2}>
              {errorMessage}
            </Text>
          ) : null}
        </Box>
        <Box width="100%">
          <Input
            variant="unstyled"
            sx={inputContainer}
            placeholder="Personal Email"
            name="privateEmail"
            value={inputData.privateEmail}
            onChange={handleInputChange}
          />
          {errorMessage === "Kindly fill in your personal email" ? (
            <Text variant="p" mt={2}>
              {errorMessage}
            </Text>
          ) : null}
        </Box>
      </Flex>
      <Flex sx={inputRow} className="input-row" gap="1rem">
        <Box width="100%">
          <Input
            variant="unstyled"
            sx={inputContainer}
            placeholder="Contact Number"
            name="contactNumber"
            value={inputData.contactNumber}
            onChange={handleInputChange}
          />
          {errorMessage === "Kindly fill in your contact number" ? (
            <Text variant="p" mt={2}>
              {errorMessage}
            </Text>
          ) : null}
        </Box>
        <Box className="single-input" width="100%">
          <Select
            variant="unstyled"
            sx={inputContainer}
            placeholder="Attending?"
            name="rsvpStatus"
            value={inputData.rsvpStatus}
            onChange={handleInputChange}
          >
            {rsvpOptions?.map((rsvpOption, index) => (
              <option key={index}>{rsvpOption}</option>
            ))}
          </Select>
          {errorMessage === "Kindly confirm your RSVP status" ? (
            <Text variant="p" mt={2}>
              {errorMessage}
            </Text>
          ) : null}
        </Box>
      </Flex>
      <Button
        isLoading={isRsvpPending ? true : false}
        loadingText="Processing RSVP"
        spinnerPlacement="end"
        variant="primaryButton"
        onClick={handleSubmit}
      >
        RSVP
      </Button>
    </Box>
  );
};

export default RsvpForm;
