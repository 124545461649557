import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    primary: {
      900: "#cb5727",
    },
    secondary: {
      900: "#e2d3bd",
    },
  },

  fonts: {
    body: "Avenir, Arial, sans-serif",
    heading: "'Avenir Black', sans-serif",
  },

  components: {
    Heading: {
      variants: {
        h1: {
          fontWeight: 600,
          fontSize: {
            base: "2.88rem",
            sm: "2.88rem",
            md: "2.88rem",
            lg: "3.05rem",
            xl: "3.05rem",
          },
          letterSpacing: "1.5px",
          color: "secondary.900",
          textTransform: "uppercase",
        },
        h2: {
          fontWeight: 600,
          fontSize: "2.48rem",
          letterSpacing: "1.5px",
          color: "secondary.900",
          textTransform: "uppercase",
        },
        h3: {
          fontWeight: 600,
          fontSize: "2.07rem",
          letterSpacing: "1.5px",
          color: "secondary.900",
          textTransform: "uppercase",
        },
        h4: {
          fontWeight: 600,
          fontSize: "1.44rem",
          letterSpacing: "1.5px",
          color: "secondary.900",
          textTransform: "uppercase",
        },
        h5: {
          fontWeight: 600,
          fontSize: "1.2rem",
          letterSpacing: "1.5px",
          color: "secondary.900",
          textTransform: "uppercase",
          fontFamily: "Avenir",
        },
        h6: {
          fontWeight: 600,
          fontSize: "1rem",
          letterSpacing: "1.5px",
          color: "secondary.900",
          textTransform: "uppercase",
          fontFamily: "Avenir",
          // marginBottom: "0.5rem"
        },
      },
    },
    Text: {
      variants: {
        p: {
          fontSize: "1rem",
          fontWeight: 300,
          color: "secondary.900",
        },
        tableText: {
          fontSize: "0.9rem",
          fontWeight: 300,
          color: "secondary.900",
        },
      },
    },
    Button: {
      variants: {
        primaryButton: {
          backgroundColor: "secondary.900",
          width: "100%",
          borderRadius: "0",
          marginTop: "1rem",
          border: "1px solid #e2d3bd",
          color: "#cb5727",
          _hover: {
            backgroundColor: "transparent",
            color: "secondary.900",
          },
        },
      },
    },
  },
});
export default theme;
