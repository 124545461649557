import React, { useEffect, useState } from "react";
import BackgroundPattern from "../../assets/backgroundPattern.svg";
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Select,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { useFetchFirebase } from "../../actions/useFetchFirebase";
import firebase from "firebase/compat/app";
import { Helmet } from "react-helmet-async";
import { onAuthStateChanged, signOut } from "@firebase/auth";
import { auth } from "../../actions/useFetchFirebase";
import { useLocation, useNavigate } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import GuestlistTable from "../components/GuestlistTable";
import NavBar from "../components/NavBar";

const RsvpDashboard = () => {
  // Handle Page Location
  const location = useLocation();

  // Styling
  const eventDetailContainer = {
    marginBottom: "1rem",
    justifyContent: { base: "center", lg: "space-between" },
    flexDirection: {
      base: "column",
      lg: "row",
    },
    gap: "2rem",
  };

  const inputStyling = {
    borderLeft: "none",
    borderRight: "none",
    borderTop: "none",
    borderBottom: "1px solid #e2d3bd",
    borderRadius: "0",
    backgroundColor: "transparent",
    marginBottom: "1rem",
    width: location.pathname === "/dashboard" ? "15rem" : "100%",

    color: "secondary.900",
    _placeholder: {
      color: "secondary.900",
    },
  };

  const inputContainer = {
    width: location.pathname === "/dashboard" ? "15rem" : "100%",
  };

  // Handle INput Data
  const [inputData, setInputData] = useState({
    category: "",
    name: "",
    surname: "",
    searchedName: "",
    searchedSurname: "",
    searchedRsvpSatus: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    e.target.value = "";
  };

  // Handle Auth
  const [user, setUser] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const activeUser = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => activeUser();
  }, []);

  useEffect(() => {
    if (user == undefined) {
      navigate("/");
    }
  }, [user]);

  const handleLogout = async () => {
    await signOut(auth);
    navigate("/login");
  };

  // Fetch Data
  const data = useFetchFirebase("events");

  const [totalRsvps, setTotalRsvps] = useState(undefined);
  const [formattedRsvps, setFormattedRsvps] = useState(undefined);
  const [isFetchingData, setIsFetchingData] = useState(true);

  useEffect(() => {
    if (data?.kickStart24?.rsvpd) {
      const rsvpArray = Object.values(data?.kickStart24?.rsvpd);
      if (rsvpArray) {
        setTotalRsvps(rsvpArray?.length);
        setFormattedRsvps(rsvpArray);
      }
    }
  }, [data]);

  useEffect(() => {
    if (data === undefined || Object.keys(data).length === 0) {
      setIsFetchingData(true);
    } else {
      setIsFetchingData(false);
    }
  }, [data]);

  const [rsvpsData, setRsvpsData] = useState({
    all: {
      rsvps: "",
      length: "",
      yes: "",
      yesCount: "",
      no: "",
      noCount: "",
      maybe: "",
      maybeCount: "",
    },
    artists: {
      rsvps: "",
      length: "",
      yes: "",
      yesCount: "",
      no: "",
      noCount: "",
      maybe: "",
      maybeCount: "",
    },
    guests: {
      rsvps: [],
      length: "",
      yes: "",
      yesCount: "",
      no: "",
      noCount: "",
      maybe: "",
      maybeCount: "",
    },
  });

  useEffect(() => {
    if (formattedRsvps) {
      // Total Filter
      const yesFilter = formattedRsvps.filter(
        (rsvp) => rsvp.rsvpStatus === "Yes"
      );
      const noFilter = formattedRsvps.filter(
        (rsvp) => rsvp.rsvpStatus === "No"
      );
      const maybeFilter = formattedRsvps.filter(
        (rsvp) => rsvp.rsvpStatus === "Maybe"
      );

      // Artist  RSVP Status Filter
      const artistFilter = formattedRsvps.filter(
        (rsvp) => rsvp.category === "artist"
      );
      const artistYesFilter = artistFilter.filter(
        (rsvp) => rsvp.rsvpStatus === "Yes"
      );
      const artistNoFilter = artistFilter.filter(
        (rsvp) => rsvp.rsvpStatus === "No"
      );
      const artistMaybeFilter = artistFilter.filter(
        (rsvp) => rsvp.rsvpStatus === "Maybe"
      );

      // Guest  RSVP Status Filter
      const guestFilter = formattedRsvps.filter(
        (rsvp) =>
          rsvp.category === "guest" ||
          rsvp.category === "last-guest" ||
          rsvp.category === "late-guest" ||
          rsvp.category == undefined
      );
      const guestYesFilter = guestFilter.filter(
        (rsvp) => rsvp.rsvpStatus === "Yes"
      );
      const guestNoFilter = guestFilter.filter(
        (rsvp) => rsvp.rsvpStatus === "No"
      );
      const guestMaybeFilter = guestFilter.filter(
        (rsvp) => rsvp.rsvpStatus === "Maybe"
      );

      setRsvpsData({
        ...rsvpsData,
        all: {
          rsvps: formattedRsvps,
          count: formattedRsvps.length,
          yes: yesFilter,
          yesCount: yesFilter.length,
          no: noFilter,
          noCount: noFilter.length,
          maybe: maybeFilter,
          maybeCount: maybeFilter.length,
        },
        artists: {
          rsvps: artistFilter,
          count: artistFilter.length,
          yes: artistYesFilter,
          yesCount: artistYesFilter.length,
          no: artistNoFilter,
          noCount: artistNoFilter.length,
          maybe: artistMaybeFilter,
          maybeCount: artistMaybeFilter.length,
        },
        guests: {
          rsvps: guestFilter,
          count: guestFilter.length,
          yes: guestYesFilter,
          yesCount: guestYesFilter.length,
          no: guestNoFilter,
          noCount: guestNoFilter.length,
          maybe: guestMaybeFilter,
          maybeCount: guestMaybeFilter.length,
        },
      });
    }
  }, [formattedRsvps, totalRsvps, formattedRsvps]);

  // Handle Selected RSVP Category
  const [selectedRsvps, setSelectedRsvps] = useState(undefined);

  useEffect(() => {
    if (inputData.category === "" || inputData.category === "all") {
      setSelectedRsvps(rsvpsData.all);
    } else if (inputData.category === "guests") {
      setSelectedRsvps(rsvpsData.guests);
    } else if (inputData.category === "artists") {
      setSelectedRsvps(rsvpsData.artists);
    }
  }, [rsvpsData, inputData.category]);

  const [activeRsvps, setActiveRsvps] = useState(undefined);
  // Handle Filter

  useEffect(() => {
    // Function to filter RSVPs based on input values
    const filterRsvps = () => {
      let filteredRsvps = formattedRsvps || [];

      // Filter by guest status

      // Filter by name
      if (inputData.searchedName) {
        filteredRsvps = filteredRsvps.filter((rsvp) =>
          rsvp.name.toLowerCase().includes(inputData.searchedName.toLowerCase())
        );
      }

      // Filter by surname
      if (inputData.searchedSurname) {
        filteredRsvps = filteredRsvps.filter((rsvp) =>
          rsvp.surname
            .toLowerCase()
            .includes(inputData.searchedSurname.toLowerCase())
        );
      }

      // Filter by RSVP status
      if (inputData.searchedRsvpSatus) {
        filteredRsvps = filteredRsvps.filter(
          (rsvp) => rsvp.rsvpStatus === inputData.searchedRsvpSatus
        );
      }

      // Update activeRsvps state with filtered data
      setActiveRsvps({
        ...rsvpsData.all,
        rsvps: filteredRsvps,
        count: filteredRsvps.length,
        yes: filteredRsvps.filter((rsvp) => rsvp.rsvpStatus === "Yes"),
        yesCount: filteredRsvps.filter((rsvp) => rsvp.rsvpStatus === "Yes")
          .length,
        no: filteredRsvps.filter((rsvp) => rsvp.rsvpStatus === "No"),
        noCount: filteredRsvps.filter((rsvp) => rsvp.rsvpStatus === "No")
          .length,
        maybe: filteredRsvps.filter((rsvp) => rsvp.rsvpStatus === "Maybe"),
        maybeCount: filteredRsvps.filter((rsvp) => rsvp.rsvpStatus === "Maybe")
          .length,
      });
    };

    // Call the filterRsvps function when input data changes
    filterRsvps();
  }, [inputData, formattedRsvps, rsvpsData.all]);

  // Handle Check In
  const [isCheckInPending, setIsCheckInPending] = useState(false);
  const [isCheckInSuccess, setIsCheckInSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleCheckIn = (index) => {
    setErrorMessage(""); // Clear previous error messages
    setIsCheckInPending(true); // Set to true while the check-in is in progress

    setActiveRsvps((prevState) => {
      const timestamp = Date.now();
      const updatedRsvps = [...prevState.rsvps];
      updatedRsvps[index] = {
        ...updatedRsvps[index],
        checkedIn: true,
        checkInTimestamp: timestamp,
      };

      // Post to Firebase
      const database = firebase.database();
      const databaseRef = database.ref(
        `events/kickStart24/rsvpd/${updatedRsvps[index].name} ${updatedRsvps[index].surname}`
      );

      databaseRef.set(updatedRsvps[index], (error) => {
        setIsCheckInPending(false); // Set back to false after the check-in attempt
        if (error) {
          setErrorMessage(
            "Could not complete guest check in please try again."
          );
        } else {
          setIsCheckInSuccess(true);
        }
      });

      return { ...prevState, rsvps: updatedRsvps };
    });
  };

  // Reset Check In
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsCheckInSuccess(false);
    }, 3000);

    // Clear the timeout if the component unmounts or IsCheckInSuccess changes before the timeout
    return () => clearTimeout(timeoutId);
  }, [isCheckInSuccess]);

  // // Handle Check In Summary
  const [checkInSummary, setCheckInSummary] = useState({
    expectedGuests: 0,
    totalCheckIns: 0,
    outsandingGuests: 0,
    checkInPercentage: 0,
  });

  useEffect(() => {
    if (location.pathname === "/check-in" && activeRsvps) {
      // Filter Check Ins
      const filteredCheckIns = activeRsvps?.yes?.filter(
        (rsvp) => rsvp?.checkedIn != undefined
      );

      setCheckInSummary({
        expectedGuests: activeRsvps?.yesCount,
        totalCheckIns: filteredCheckIns.length,
        outsandingGuests: activeRsvps?.yesCount - filteredCheckIns.length,
        checkInPercentage:
          activeRsvps?.yesCount > 0
            ? Math.round(
                (filteredCheckIns.length / activeRsvps?.yesCount) * 100
              )
            : 0,
        graphData: "",
      });
    }
  }, [activeRsvps]);

  // Format Graph Data
  // const checkInGraphData, setCheckInGraphData = useState({
  //   labels: "",
  //   dataSets: "",
  // })

  // useEffect(() => {

  // }, [])

  return (
    <>
      <Helmet>
        <title>Duma Collective Kick Start Dashboard</title>
        <meta name="description" content="RSVP Dashboard" />
        <link rel="canonical" href="/dashboard" />
        <meta name="robots" content="noindex" />
      </Helmet>

      <Box
        className="page-container"
        minHeight="100vh"
        minWidth="100vw"
        backgroundImage={BackgroundPattern}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding={{
          base: "6rem 2rem 6rem 2rem",
          md: "8rem 2rem 8rem 2rem",
          lg: "2.5rem 2rem",
        }}
        overflowX="scroll"
      >
        {isFetchingData ? (
          <Spinner color="secondary.900" size="xl" />
        ) : (
          <>
            <NavBar />
            <Box paddingY="1rem">
              <Heading variant="h3" marginBottom="2rem">
                {location.pathname === "/dashboard"
                  ? "RSVP Dashboard"
                  : "Guest Check-In"}
              </Heading>
              <Flex className="rsvp-summary" sx={eventDetailContainer}>
                <Box width={{ base: "100%", lg: "15rem" }}>
                  <Heading
                    textAlign="left"
                    variant="h6"
                    dangerouslySetInnerHTML={{
                      __html: `Geust Category`,
                    }}
                  />
                  <Select
                    variant="unstyled"
                    sx={inputStyling}
                    placeholder="All"
                    name="category"
                    value={inputData.category}
                    onChange={handleInputChange}
                  >
                    <option value="guests">Guests</option>
                    <option value="artists">Artists</option>
                  </Select>
                </Box>
                {location.pathname === "/dashboard" ? (
                  <>
                    <Box width="9rem">
                      <Heading
                        variant="h6"
                        dangerouslySetInnerHTML={{
                          __html: `Total <br /> RSVP <br /> ${totalRsvps}`,
                        }}
                      />
                    </Box>
                    <Box width="9rem">
                      <Heading
                        variant="h6"
                        dangerouslySetInnerHTML={{
                          __html: `Category Total <br /> ${activeRsvps.count}`,
                        }}
                      />
                    </Box>
                    <Box width="9rem">
                      <Heading
                        variant="h6"
                        dangerouslySetInnerHTML={{
                          __html: `<br /> Yes <br /> ${activeRsvps.yesCount}`,
                        }}
                      />
                    </Box>
                    <Box width="9rem">
                      <Heading
                        variant="h6"
                        dangerouslySetInnerHTML={{
                          __html: `<br /> Maybe <br /> ${activeRsvps.maybeCount}`,
                        }}
                      />
                    </Box>
                    <Box width="9rem">
                      <Heading
                        variant="h6"
                        dangerouslySetInnerHTML={{
                          __html: `<br /> No <br /> ${activeRsvps.noCount}`,
                        }}
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box>
                      <Heading
                        variant="h5"
                        dangerouslySetInnerHTML={{
                          __html: `Total <br /> RSVP`,
                        }}
                      />
                      <Heading variant="h6">
                        {checkInSummary.expectedGuests}
                      </Heading>
                    </Box>
                    <Box>
                      <Heading
                        variant="h5"
                        dangerouslySetInnerHTML={{
                          __html: `Total <br /> Check-ins`,
                        }}
                      />
                      <Heading variant="h6">
                        {checkInSummary.totalCheckIns}
                      </Heading>
                    </Box>

                    <Box>
                      <Heading
                        variant="h5"
                        dangerouslySetInnerHTML={{
                          __html: `Outsanding  <br /> Guests`,
                        }}
                      />
                      <Heading variant="h6">
                        {checkInSummary.outsandingGuests}
                      </Heading>
                    </Box>

                    <Box>
                      <Heading
                        variant="h5"
                        dangerouslySetInnerHTML={{
                          __html: `Check-in  <br /> Percentage`,
                        }}
                      />
                      <Heading variant="h6">
                        {checkInSummary.checkInPercentage}%
                      </Heading>
                    </Box>
                  </>
                )}
              </Flex>

              <Box className="filter-container" marginBottom="1rem">
                <Heading variant="h4" marginBottom="1rem">
                  {location.pathname === "/dashboard"
                    ? "Filter"
                    : "Search Guests"}
                </Heading>
                <Flex
                  className="event-inputs-container"
                  sx={eventDetailContainer}
                >
                  <Box sx={inputContainer}>
                    <Input
                      variant="unstyled"
                      sx={inputStyling}
                      placeholder="Name"
                      name="searchedName"
                      value={inputData.searchedName}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box sx={inputContainer}>
                    <Input
                      variant="unstyled"
                      sx={inputStyling}
                      placeholder="Surname"
                      name="searchedSurname"
                      value={inputData.searchedSurname}
                      onChange={handleInputChange}
                    />
                  </Box>
                  {location.pathname === "/dashboard" ? (
                    <Box sx={inputContainer}>
                      <Select
                        variant="unstyled"
                        sx={inputStyling}
                        placeholder="RSVP Status"
                        name="searchedRsvpSatus"
                        value={inputData.searchedRsvpSatus}
                        onChange={handleInputChange}
                      >
                        <option value="Yes">Yes</option>
                        <option value="Maybe">Maybe</option>
                        <option value="No">No</option>
                      </Select>
                    </Box>
                  ) : null}
                </Flex>
              </Box>
              <Box maxHeight={{ base: "70vh", lg: "29vh" }} overflowY="scroll">
                <GuestlistTable
                  inputData={inputData}
                  activeRsvps={activeRsvps}
                  location={location}
                  handleCheckIn={handleCheckIn}
                  isCheckInPending={isCheckInPending}
                  isCheckInSuccess={isCheckInSuccess}
                />
              </Box>
              <CSVLink data={activeRsvps.yes} filename={"Guestlist.csv"}>
                <Button variant="primaryButton">Download Guestlist</Button>
              </CSVLink>
              <Button variant="primaryButton" onClick={handleLogout}>
                Logout
              </Button>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default RsvpDashboard;
