import "./App.css";
import "./index.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Rsvp from "./features/pages/Rsvp";
import ThankYou from "./features/pages/ThankYou";
import RsvpDashboard from "./features/pages/RsvpDashboard";
import ScrollToTop from "./actions/ScrollToTop";
import Login from "./features/pages/Login";

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Rsvp />} />
          <Route path="/rsvp" element={<Rsvp />} />
          <Route path="/latter-rsvp" element={<Rsvp />} />
          <Route path="/last-rsvp" element={<Rsvp />} />
          <Route path="/artist" element={<Rsvp />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/dashboard" element={<RsvpDashboard />} />
          <Route path="/check-in" element={<RsvpDashboard />} />
          <Route path="/analytics" element={<RsvpDashboard />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
