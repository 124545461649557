import React from "react";
import {
  Flex,
  Heading,
  LinkBox,
  LinkOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import RsvpSuccessModel from "./RsvpSuccessModel";

const GuestlistTable = ({
  inputData,
  activeRsvps,
  location,
  handleCheckIn,
  isCheckInPending,
  isCheckInSuccess = { isCheckInSuccess },
}) => {
  const standardCellContainter = {
    maxWidth: "100px",
    width: "100px",
  };

  const emailCellContainter = {
    maxWidth: "270px",
    width: "270px",
  };

  const checkInContainter = {
    maxWidth: "100px",
    width: "100px",
  };

  const checkInIconCellStyling = {
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  };

  return (
    <>
      <TableContainer overflow="scroll">
        <Table variant="unstyled" size="sm">
          <Thead paddingBottom="0">
            <Tr borderY="1px solid #e2d3bd">
              <Th sx={standardCellContainter}>
                <Heading variant="h6">
                  {inputData.category == "artists" ? "Artist Name" : "Name"}
                </Heading>
              </Th>
              <Th
                sx={standardCellContainter}
                display={inputData.category == "artists" ? "none" : "block"}
              >
                <Heading variant="h6">Surname</Heading>
              </Th>
              <Th sx={emailCellContainter}>
                <Heading variant="h6">Number</Heading>
              </Th>
              {location.pathname === "/dashboard" ? (
                <>
                  <Th sx={emailCellContainter}>
                    <Heading variant="h6">Work Email</Heading>
                  </Th>
                  <Th sx={emailCellContainter}>
                    <Heading variant="h6">Personal Email</Heading>
                  </Th>
                  <Th>
                    <Heading variant="h6">RSVP</Heading>
                  </Th>
                </>
              ) : (
                <>
                  <Th>
                    <Heading variant="h6" textAlign="center">
                      Check-in Status
                    </Heading>
                  </Th>
                </>
              )}
            </Tr>
          </Thead>
          <Tbody>
            {activeRsvps?.rsvps &&
              activeRsvps.rsvps.map((rsvp, index) => (
                <Tr
                  borderBottom="1px solid #e2d3bd"
                  key={index}
                  className="guest-row"
                >
                  <Td className="cell-container" sx={standardCellContainter}>
                    <Text className="table-text" variant="tableText">
                      {rsvp?.name}
                    </Text>
                  </Td>
                  <Td className="cell-container" sx={standardCellContainter}>
                    <Text
                      className="table-text"
                      variant="tableText"
                      display={
                        inputData.category === "artists" ? "none" : "block"
                      }
                    >
                      {rsvp?.surname}
                    </Text>
                  </Td>
                  <Td className="cell-container" sx={standardCellContainter}>
                    <Text className="table-text" variant="tableText">
                      {rsvp?.contactNumber}
                    </Text>
                  </Td>
                  {location.pathname === "/dashboard" ? (
                    <>
                      <Td className="cell-container" sx={emailCellContainter}>
                        <Text className="table-text" variant="tableText">
                          {rsvp?.workEmail}
                        </Text>
                      </Td>
                      <Td className="cell-container" sx={emailCellContainter}>
                        <Text className="table-text" variant="tableText">
                          {rsvp?.privateEmail}
                        </Text>
                      </Td>
                      <Td className="cell-container">
                        <Text className="table-text" variant="tableText">
                          {rsvp?.rsvpStatus}
                        </Text>
                      </Td>
                    </>
                  ) : (
                    <Td sx={checkInContainter}>
                      {!rsvp?.checkedIn ? (
                        <>
                          <Link
                            onClick={() => handleCheckIn(index)}
                            className="check-in-container"
                          >
                            <Flex sx={checkInIconCellStyling}>
                              <AiFillCheckCircle
                                fontSize="2rem"
                                className="check-in-icon"
                              />
                              <Text
                                variant="tableText"
                                textAlign="center"
                                className="table-text"
                              >
                                Check In
                              </Text>
                            </Flex>
                          </Link>
                        </>
                      ) : (
                        <Flex
                          sx={checkInIconCellStyling}
                          className="check-in-container"
                        >
                          <AiFillCloseCircle className="check-in-icon" />
                          <Text
                            className="table-text"
                            variant="tableText"
                            textAlign="center"
                          >
                            Checked-In
                          </Text>
                        </Flex>
                      )}
                    </Td>
                  )}
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <RsvpSuccessModel isOpen={isCheckInSuccess} />
    </>
  );
};

export default GuestlistTable;
